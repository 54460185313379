var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"ModalQRUser",attrs:{"hide-header":"","hide-footer":"","centered":""},on:{"hidden":_vm.hide},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',[_c('div',{staticClass:"text-center my-3 title-profile"},[_vm._v(" PROFILE QR CODE ")]),_c('div',{staticClass:"text-center",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"img-zindex"},[_c('label',{staticClass:"panel-image my-auto"},[(_vm.userProfile.pictureUrl)?_c('div',{staticClass:"profile-img",style:({
                                backgroundImage: 'url(' + _vm.userProfile.pictureUrl + ')'
                            })}):(_vm.profile.picture)?_c('div',{staticClass:"profile-img",style:({
                                backgroundImage: 'url(' + _vm.profile.picture + ')'
                            })}):_c('div',{staticClass:"profile-img",style:({
                                backgroundImage: 'url(' + _vm.pictureProfile + ')'
                            })})])]),_c('div',[_c('div',{staticClass:"dot dot-left",staticStyle:{"left":"0","z-index":"1"}}),_c('div',{staticClass:"border-behind"}),_c('div',{staticClass:"dot dot-right",staticStyle:{"right":"0"}})])]),_c('div',{staticClass:"text-center text-bold color-gray-ft"},[_c('div',[_vm._v(_vm._s(_vm.profile.first_name_th)+" "+_vm._s(_vm.profile.last_name_th))]),_c('div',[_vm._v("-")])]),_c('div',{staticClass:"text-center"},[_c('vue-qrcode',{staticClass:"qr-width-70",attrs:{"value":_vm.profile.telephone}})],1)]),_c('div',{staticClass:"border-top p-3 text-center btn-confirm-popup"},[_c('div',{on:{"click":_vm.hide}},[_vm._v("ปิดหน้าต่างนี้")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }