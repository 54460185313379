<template>
  <div class="content-page pt-2">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div class="profile-container">
        <div>
          <ProfileComponent :profile="profile" :userProfile="lineProfile" />
        </div>
      </div>
      <div class="my-2">
        <div class="menulist-container">
          <div class="p-2">
            <BannerProfilePanel
              v-if="bannerList.length > 0"
              :bannerList="bannerList"
              :profile="profile"
            />
            <MenuListComponentOld />
          </div>
        </div>
      </div>
    </div>
    <ModalRegisterAlert ref="ModalRegisterAlert" :lineProfile="lineProfile" />
  </div>
</template>

<script>
import ProfileComponent from "@/components/profile/ProfileComponent";
import MenuListComponent from "@/components/profile/MenuListComponent";
import BannerProfilePanel from "@/components/profile/BannerProfilePanel";
import MenuListComponentOld from "@/components/profile/MenuListComponentOld";
import ModalRegisterAlert from "@/components/profile/modal/ModalRegisterAlert";
import OtherLoading from "@/components/other/OtherLoading";
import VueCookies from "vue-cookies";
export default {
  components: {
    ProfileComponent,
    MenuListComponent,
    ModalRegisterAlert,
    OtherLoading,
    MenuListComponentOld,
    BannerProfilePanel
  },
  mounted() {
    if (VueCookies.get("popupModalRegister")) {
      this.$refs.ModalRegisterAlert.show();
    }
  },
  data() {
    return {
      profile: {},
      lineProfile: {},
      isLoading: true,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      },
      privilegeList: [],
      notFoundData: false,
      rewardList: [],
      filterNews: {
        page: 1,
        take: 10
      },
      newsList: [],
      bannerList: [],
      filterBanner: {
        level_id: 1
      }
    };
  },
  beforeCreate: async function () {
    this.$liff.init({ liffId: this.$liff_ID_Login });
  },
  async created() {
    this.liffLogin();
  },
  methods: {
    async liffLogin() {
      this.$liff.ready
        .then(() => {
          if (!this.$liff.isLoggedIn()) {
            this.$liff.login().then(() => {
              this.$liff.getProfile().then(profile => {
                this.$cookies.set(
                  "user_profile_token",
                  profile,
                  60 * 60 * 24 * 30
                );
                this.lineProfile = profile;
                this.LoginExternal();
              });
            });
          } else {
            this.$liff.getProfile().then(profile => {
              this.$cookies.set(
                "user_profile_token",
                profile,
                60 * 60 * 24 * 30
              );
              this.lineProfile = profile;
              this.LoginExternal();
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getUserProfile() {
      this.$cookies.set("hourglass_register_success");
      this.isLoading = true;
      await this.$store.dispatch("getUserApi");
      this.profile = this.$store.state.shortProfile;
      this.lineProfile = this.$cookies.get("user_profile_token");

      this.isLoading = false;
    },
    async getBanner() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/banner/campaign_form`)
        .then(async data => {
          if (data.result == 1) {
            if (data.detail.totalCount > 0) {
              this.bannerList = data.detail.detail;
            }
          }
        });
    },
    async getCouponList() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/privilege/coupon`)
        .then(async data => {
          if (data.result == 1) {
            if (data.detail.length > 0) {
              this.notFoundData = false;
              this.privilegeList = data.detail;
            } else {
              this.notFoundData = true;
            }
          }
        });
    },
    async getRewardList() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/redeem/get_redeem_item_list`)
        .then(
          async data => {
            // this.isLoading = false;
            if (data.result == 1) {
              this.rewardList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async getNewsList() {
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/MonthlyPrivilege/list`,
          this.filterNews
        )
        .then(async data => {
          // this.isLoading = false;
          if (data.result == 1) {
            this.newsList = data.detail.detail;
            this.isLoading = false;
          }
        });
    },
    async unpairfunc() {
      this.filter.SocialId = this.lineProfile.userId;
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/unpair`, this.filter)
        .then(async data => {
          if (data.result == 1) {
            this.$cookies.remove("hourglass_token");
            this.$router.push("/validatetelephone");
          }
        });
    },
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 0) {
            this.$cookies.remove("hourglass_register_success");
            this.$cookies.remove("hourglass_token");
            this.$router.push("/validatetelephone");
          } else {
            this.$cookies.set("hourglass_token", data.detail.token);
            // this.$router.push("/profile");
            await this.getUserProfile();
            await this.getBanner();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-container {
  background: #fcfcfc;
  margin: 1rem;
  border-radius: 5px;
}
.menulist-container {
  background: #fcfcfc;
  border-radius: 5px;
  margin: 1rem;

  // padding-bottom: 100px;
}
.text-profile {
  font-size: 23px;
  font-weight: 600;
}
.line-text-pf {
  border-left: 6px solid black;
}
.btn-unpair {
  background-color: transparent;
  border: none;
  color: #bebebe;
}
.w-90 {
  width: 90%;
}
.content-page {
  margin-top: 80px;
  // margin-bottom: 100px;
  // min-height: 100vh;
}
</style>
