<template>
    <div>
        <b-modal
            v-model="showModal"
            ref="ModalQRUser"
            hide-header
            hide-footer
            centered
            @hidden="hide"
        >
            <div>
                <div class="text-center my-3 title-profile">
                    PROFILE QR CODE
                </div>                
                <div class="text-center" style="position:relative;">
                    <div class="img-zindex">
                        <label class="panel-image my-auto">
                            <div
                                v-if="userProfile.pictureUrl"
                                class="profile-img"
                                v-bind:style="{
                                    backgroundImage: 'url(' + userProfile.pictureUrl + ')'
                                }"
                            ></div>
                            <div
                                v-else-if="profile.picture"
                                class="profile-img"
                                v-bind:style="{
                                    backgroundImage: 'url(' + profile.picture + ')'
                                }"
                            ></div>
                            <div
                                v-else
                                class="profile-img"
                                v-bind:style="{
                                    backgroundImage: 'url(' + pictureProfile + ')'
                                }"
                            ></div>
                        </label>
                    </div>
                    <div>
                        <div class="dot dot-left" style="left:0; z-index: 1;"></div>
                        <div class="border-behind"></div>
                        <div class="dot dot-right" style="right:0;"></div>
                    </div>
                </div>
                <div class="text-center text-bold color-gray-ft">
                    <div>{{profile.first_name_th}} {{profile.last_name_th}}</div>
                    <div>-</div>
                </div>
                <div class="text-center">
                    <vue-qrcode class="qr-width-70" :value="profile.telephone" />
                </div>
            </div>
            <div class="border-top p-3 text-center btn-confirm-popup">
                <div @click="hide">ปิดหน้าต่างนี้</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import picture from "@/assets/images/default-image.png";
export default {
    props:{
        profile:{
            required:true,
            type:Object
        },
        userProfile:{
            required:false,
            type:Object
        }
    },
    components:{
        VueQrcode
    },
    data(){
        return{
            showModal : false,
            pictureProfile : picture
        }
    },
    methods: {
        show() {
            this.showModal = true;
        },
        hide() {
            this.showModal = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-confirm-popup{
    background-color: #000;
    color: #fff;
    border-top: 1px solid #929292;
}
.text-bold{
    font-weight: 600;
    font-size: 18px;
    padding: 10px 0;
}
.profile-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 50%;
}
.panel-image {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 3px solid #000;
    border-radius: 50%;
    bottom: 0px;
}
.title-profile{
    font-weight: bold;
    font-size: 20px;
}
.border-behind{
    border-top: 4px dashed #dfdfdf;
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0;
}
.img-zindex{
    z-index: 1;
    position: relative;
}
.dot {
    display: inline-block;
    top: 30%;
    position: absolute;
    width: 20px;
    height: 35px;
    border-left: 0;
    background-color: gray;
}
.dot-left{ 
    margin-left: -17px;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    
}
.dot-right{
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    margin-right: -16px;
}
.color-gray-ft{
    color: #9d9d9d;
}
.qr-width-70{
    width: 80%;
}
</style>