<template>
    <div>
        <div class="bg-menu-list">
            <b-row class="px-1">
                <b-col class="list-title">
                    {{title}}
                </b-col>
                <b-col class="text-right ft-14">
                     <a href="/privilege">ดูทั้งหมด</a>
                </b-col>
            </b-row>
            <VueSlickCarousel v-bind="slickOptions">
                <div
                    v-for="(item,index) in list"
                    :key="index"
                >
                    <div class="content-cards">
                        <a @click="toDetail(item)" class="pointer">
                            <b-row class="justify-content-center align-items-lg-center">
                                <b-col cols="5" class="border-r">
                                    <b-img-lazy
                                        v-if="item.image_url"
                                        v-bind="mainProps"
                                        :src="item.image_url"
                                        class="lazy-image"
                                    ></b-img-lazy>
                                    <b-img-lazy
                                        v-else
                                        v-bind="mainProps"
                                        :src="img"
                                        class="lazy-image"
                                    ></b-img-lazy>
                                </b-col>
                                <b-col cols="7">
                                    <div class="title">{{item.name}}</div>
                                    <div class="description">{{item.detail}}</div>
                                    <div class="expire-date">วันหมดอายุ: {{item.expired_date ? dateFormat(item.expired_date) : '-'}}</div>
                                </b-col>
                            </b-row>
                        </a>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import PrivilegeListComponent from "@/components/privilege/PrivilegeListComponent";
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import picture from "@/assets/images/default-image.png";
import moment from 'moment';
export default {
    components:{
        PrivilegeListComponent,
        VueSlickCarousel
    },
    props:{
        list:{
            required:true,
            type:Array
        },
        title:{
            required:true,
            type:String
        }
    },
    data() {
      return {
          mainProps: {
            center: true,
            fluidGrow: false,
            blank: true, 
            blankColor: "#bbb",
            width: 600,
            height: 600,
            class: ""
        },
        slickOptions: {
            infinite: false,
            dots:false,
            arrows:false,
            slidesToShow: 1,
            mobileFirst:true,
            variableWidth: true
            // infinite: false,
            // dots:false,
            // arrows:false,
            // slidesToShow: 1,
            // centerMode:true,
            // mobileFirst:true,
        },
        img : picture
      }
    },
    methods:{
        dateFormat(date){
            return moment(date).locale('th').format('lll').replace("เวลา","");
        },
        toDetail(item){
            if(item.status === 1){
                this.$router.push("/privilege/" + item.privilege_mapping_id);
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    font-size: 14px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #000;
}
.description{
    font-size: 12px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.expire-date{
    font-size: 11px;
}
.content-menu-list{
    background-color: #f1f1f1;
     margin: 1rem;
    border-radius: 10px;
}
.btn-menu-list{
    background-color: transparent;
    border:none;
    color: #000 !important;
}
::v-deep a {
    color : gray;
}
.content-privilege{
    background-color: #fff;
     margin: 1rem;
    // min-height: 100vh;
    border-radius: 5px;
}
.content-cards{
    border-radius: 3px;
    background-color: #f1f1f1;
    margin: 10px 0;
    padding: 10px 10px;
    outline: none;
}
::v-deep .slick-list{
    padding: 0px 0px!important;
}
.lazy-image {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
::v-deep .slick-slide.slick-active.slick-center.slick-current{
    padding-right: 10px;
}
::v-deep .slick-slide.slick-active.slick-current{
    margin-left: 0px;
}
.list-title{
    font-weight: bold;
    font-size: 18px;
}
.ft-14{
    font-size: 14px;
    text-decoration: underline;
}
.border-r{
    border-right: 1px solid #d7d7d7;
}
::v-deep .slick-slide {
    width: 280px;
}
::v-deep .slick-slide{
    margin-left: 7px;
}
::v-deep .slick-track{
    outline: none;
}
.display-none{
    display: none!important;
}
</style>