<template>
  <div>
    <slick
      ref="slick"
      v-bind="slickOptions"
      :class="bannerList.length > 1 ? 'mb-4' : ''"
    >
      <div v-for="(item, index) in bannerList" :key="index" class="pointer">
        <div @click="getForm(item)">
          <div
            class="header-image"
            v-bind:style="
              !bannerList.length > 0
                ? {
                    backgroundImage:
                      'url(' + '/assets/images/default-image.png' + ')'
                  }
                : {
                    backgroundImage: 'url(' + item.image_url + ')'
                  }
            "
          ></div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel
  },
  props: {
    bannerList: {
      required: true,
      type: Array
    },
    profile: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      slickOptions: {
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 6000
      }
    };
  },
  methods: {
    getForm(item) {
      console.log(item);
      const url = new URL(item.redirect_id);
      const parameter = url.searchParams.get("urlkey");
      this.$router.push(
        `/form?urlKey=${parameter}&path=${item.redirect_id}&guid=${this.profile.user_guid}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.header-image {
  width: 100%;
  padding-top: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
