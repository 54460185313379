<template>
  <div class="content-profile">
    <b-row class="sub-content">
      <b-col cols="4">
        <label class="panel-image my-auto">
          <div
            @click="modalQR"
            v-if="userProfile.pictureUrl"
            class="profile-img"
            v-bind:style="{
              backgroundImage: 'url(' + userProfile.pictureUrl + ')'
            }"
          ></div>
          <div
            @click="modalQR"
            v-else-if="profile.picture"
            class="profile-img"
            v-bind:style="{
              backgroundImage: 'url(' + profile.picture + ')'
            }"
          ></div>
          <div
            @click="modalQR"
            v-else
            class="profile-img"
            v-bind:style="{
              backgroundImage: 'url(' + pictureProfile + ')'
            }"
          ></div>
        </label>
        <div @click="modalQR" class="text-center qr-custom">
          <vue-qrcode :value="profile.telephone" class="qr-size" />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="width-sub-content">
          <div>
            <span class="name"
              >{{ profile.first_name_th }} {{ profile.last_name_th }}
            </span>
          </div>
          <div class="ft-13">
            <font-awesome-icon
              :icon="['fas', 'birthday-cake']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">{{
              profile.have_year_birth === false
                ? dateFormat(profile.birthday, "DD/MM")
                : dateFormat(profile.birthday, "DD/MM/YYYY")
            }}</span>
          </div>
          <div class="ft-13">
            <font-awesome-icon
              :icon="['fas', 'envelope']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">{{ profile.email }}</span>
          </div>
          <div class="ft-13">
            <font-awesome-icon
              :icon="['fas', 'phone-alt']"
              class="icon-color-gray"
            />
            <span class="pl-2 profile-ft-color">{{ profile.telephone }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="text-right" cols="2">
        <div class="my-3">
          <font-awesome-icon
            :icon="['fas', 'ellipsis-v']"
            class="icon-edit-profile"
            @click="$router.push('/editprofile')"
          />
        </div>
      </b-col>
    </b-row>
    <div class="content-menu-list p-3">
      <b-row>
        <b-col cols="6" class="point-panel" @click="$router.push('/point')">
          <div class="ft-10 d-flex align-items-center justify-content-between">
            คะแนนคงเหลือ / ระดับสมาชิก:
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </div>
          <div class="ft-13">
            <div class="ft-13">
              <font-awesome-icon :icon="['fas', 'gift']" class="mr-1" />
              <span class="ft-weight">{{
                profile.total_point | numeral("0,0")
              }}</span>
              คะแนน ({{ profile.display }})
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="ft-10 d-flex align-items-center justify-content-between">
            ช่องทางสะสมคะแนน:
            <font-awesome-icon
              @click="$router.push('/marketplace')"
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </div>
          <div class="marketplace-panel">
            <div class="icon-marketplace">
              <!-- <div class="cover"></div> -->
              <img
                src="../../assets/images/icon-shopee.png"
                alt="Marketplace icon"
                style="opacity: 0.5"
              />
            </div>
            <div class="icon-marketplace">
              <!-- <div class="cover"></div> -->
              <img
                src="../../assets/images/icon-shopee.png"
                alt="Marketplace icon"
              />
            </div>
            <div class="icon-marketplace">
              <!-- <div class="cover"></div> -->
              <img
                src="../../assets/images/icon-lazada.png"
                alt="Marketplace icon"
                style="opacity: 0.5"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <ModalQRUser
      ref="ModalQRUser"
      :profile="profile"
      :userProfile="userProfile"
      class="mx-2"
    />
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import moment from "moment";
import picture from "@/assets/images/default-image.png";
import ModalQRUser from "@/components/profile/modal/ModalQRUser";
export default {
  components: {
    VueQrcode,
    ModalQRUser
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      pictureProfile: picture
    };
  },
  methods: {
    dateFormat(value, format) {
      if (!value) return;
      if (!format) format = "DD-MM-YYYY";
      return moment(value).format(format);
    },
    modalQR() {
      this.$refs.ModalQRUser.show();
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-marketplace {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
  .cover {
    background: #858585;
    opacity: 0.5;
    position: absolute;
    top: 0;
    height: 30px;
    width: 30px;
    left: 0;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* overflow: hidden; */
  position: relative;
  padding: 1px;
  margin-top: 2px;
}
.content-profile {
  margin: 0.5rem;
  padding: 10px 0;
  border-radius: 10px;
}
.name {
  font-size: 15px;
  font-weight: 600;
}
.sub-content {
  padding: 10px;
  position: relative;
}
.icon-edit-profile {
  font-size: 20px;
  color: #858585;
}
.profile-ft-color {
  color: #858585;
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
}
.box-point {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}
.position-img {
  position: absolute;
  right: 120px;
  top: 110px;
}
.pd-box-point {
  padding: 45px 0;
}
.qr-custom {
  position: absolute;
  top: 70px;
  margin-left: 50px;
}
.qr-size {
  width: 65%;
}
.content-menu-list {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.ft-14 {
  font-size: 14px;
}
.ft-13 {
  font-size: 13px;
}
.ft-10 {
  font-size: 10px;
}
.icon-color {
  color: #c9c9c9;
}
.icon-color-gray {
  color: #5d5d5d;
}
.ft-15 {
  font-size: 15px;
}
.ft-weight {
  font-weight: bold;
}
.width-sub-content {
  width: 190px;
}
.icon-level {
  width: 25%;
}
@media only screen and (orientation: portrait) and (min-width: 737px) and (max-width: 1023px) {
  /* For iPad mini , iPad */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 12%;
  }
}
@media only screen and (orientation: portrait) and (min-width: 1024px) and (max-width: 1366px) {
  /* For iPad Pro */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 10%;
  }
}
.point-panel {
  border-right: 1px solid #c9c9c9;
  line-height: 2;
}
.marketplace-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
}
</style>
