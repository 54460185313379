var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('slick',_vm._b({ref:"slick",class:_vm.bannerList.length > 1 ? 'mb-4' : ''},'slick',_vm.slickOptions,false),_vm._l((_vm.bannerList),function(item,index){return _c('div',{key:index,staticClass:"pointer"},[_c('div',{on:{"click":function($event){return _vm.getForm(item)}}},[_c('div',{staticClass:"header-image",style:(!_vm.bannerList.length > 0
              ? {
                  backgroundImage:
                    'url(' + '/assets/images/default-image.png' + ')'
                }
              : {
                  backgroundImage: 'url(' + item.image_url + ')'
                })})])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }