<template>
  <div>
    <div class="content-menu-list bg-menulist">
      <div class="menu-list" @click="$router.push('/privilege')">
        <b-row>
          <b-col cols="3">
            <span class="px-3">
              <font-awesome-icon :icon="['fas', 'ticket-alt']" class="ft-20" />
            </span>
          </b-col>
          <b-col class="p-0" cols="7">
            <span>สิทธิพิเศษ</span>
          </b-col>
          <b-col cols="2">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="bg-menulist">
      <div class="menu-list" @click="$router.push('/reward')">
        <b-row>
          <b-col cols="3">
            <span class="px-3">
              <font-awesome-icon :icon="['fas', 'gift']" class="ft-20" />
            </span>
          </b-col>
          <b-col class="p-0" cols="7">
            <span>ของขวัญ</span>
          </b-col>
          <b-col cols="2">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="menu-list-bottom bg-menulist">
      <div @click="$router.push('/news')">
        <b-row>
          <b-col cols="3">
            <span class="px-3">
              <font-awesome-icon :icon="['fas', 'newspaper']" class="ft-20" />
            </span>
          </b-col>
          <b-col class="p-0" cols="7">
            <span>ข่าวสาร</span>
          </b-col>
          <b-col cols="2">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>

<style lang="scss" scoped>
.bg-menulist {
  background-color: #f1f1f1;
}
.content-menu-list {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.menu-list {
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 0;
}
.menu-list-bottom {
  padding: 20px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ft-20 {
  font-size: 20px;
}
.icon-color {
  color: #c9c9c9;
}
</style>
